import Scale from "../../assets/groupScale.svg";
import Store from "../../assets/groupStore.svg";
import Setting from "../../assets/groupSetting.svg";
import webIcon from "../../assets/webIcon.svg";
import Business from "assets/business.svg";
import Medical from "assets/medical.svg";

export const data = [
  {
    id: 1,
    image: Scale,
    text: "Legal",
  },
  {
    id: 2,
    image: Store,
    text: "Retail",
  },
  {
    id: 3,
    image: Setting,
    text: "Manufacturing",
  },
];

export const DocumentData = [
  {
    id: 1,
    image: Scale,
    text: "משפטי",
  },
  {
    id: 2,
    image: Store,
    text: "תוכנות ואפליקציות",
    
  },
  {
    id: 3,
    image: Setting,
    text: "גיימינג",
  },
];
export const languages = [
  {
    id: 1,
    hrefLink: "",
    text: "ספרדית",
  },
  {
    id: 2,
    hrefLink: "",
    text: "איטלקית",
  },
  {
    id: 3,
    hrefLink: "",
    text: "צרפתית",
  },
  {
    id: 4,
    hrefLink: "",
    text: "ערבית",
  },
  {
    id: 5,
    hrefLink: "",
    text: "סינית",
  },
  {
    id: 6,
    hrefLink: "",
    text: "קוריאנית",
  },
  {
    id: 7,
    hrefLink: "",
    text: "גרמנית",
  },
  {
    id: 8,
    hrefLink: "",
    text: "יפנית",
  },
  {
    id: 9,
    hrefLink: "",
    text: "שוודית",
  },
  {
    id: 10,
    hrefLink: "",
    text: "רוסית",
  },
];
export const ServicesData = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "הצהרות בנקאיות",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "רשומות רפואיות",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "מאמרים אקדמיים",
  },
  {
    id: 4,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "מסמכים פומביים",
  },
  {
    id: 5,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "חומרים שיווקיים",
  },
  {
    id: 6,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "אתרים ואפליקציות",
  },
];

export const ServicesData2 = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "הצהרות בנקאיות",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "מאמרים אקדמיים",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "חומרים שיווקיים",
  },
];

export const DocData = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
  {
    id: 4,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "License Agreements",
  },
  {
    id: 5,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Patents",
  },
  {
    id: 6,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Technical Reports",
  },
];

export const DocData2 = [
  {
    id: 1,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Apostilles",
  },
  {
    id: 2,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Birth / Marriage Certificates",
  },
  {
    id: 3,
    icon: "https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg",
    text: "Diplomas",
  },
];
