import React from "react";
import AdContact from "./AdContact";
import OptimizedImage from "hooks/OptimizedImage";
import Services from "components/LP/Services";
import { Data } from "components/LP/data";
import MapPeople from "assets/mapPeople.svg";

const SeventhAdComp = ({
  handleTop,
  content,
  midComp,
  lastSection,
  CTA,
  paddingY,
  serviceSection,
  head,
  forHebrew,
}) => {
  return (
    <div
      className={`bg-gradient-to-b from-white to-[#E8F1F5] ${
        paddingY ? paddingY : "pt-[8rem]"
      }`}
    >
      <div className="flex flex-col justify-center mx-auto w-full sm:-10/12 2xl:w-7/12 px-8 sm:px-0">
        <div className="mx-auto">
          <h2 className="text-[35px] sm:text-[40px] font-bold text-[#00173A] text-center font-secondary text-rtl">
            {head ? head : "Assured By the ISO For Exceptional Standards"}
          </h2>
          {content ? (
            <p className="text-[15px] sm:text-[18px] text-[#00173A] leading-loose text-center pt-6 w-full max-w-[916px] font-opensans px-2 text-rtl">
              {content}
            </p>
          ) : null}
          <div className="flex sm:flex-row flex-col mx-auto justify-center gap-x-5 gap-y-5 pb-8">
            <ul className="div w-full gap-x-[6.75rem] flex lg:flex-row flex-col justify-center items-center lg:relative pt-6 lg:pt-14">
              <li className="logos flex flex-col items-center w-[175px]">
                <OptimizedImage
                  src="	https://www.tomedes.com/static/9001-da0972656b253aad9080ecef8f1922e1.webp"
                  alt="ISO 9001:2015"
                  className="lg:pb-12 w-[10rem] h-auto"
                />
                <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                  <p className="text-[18px] font-opensans font-medium text-[#313E5A] pt-4  text-center">
                    ISO 9001:2015
                  </p>
                  <p className="text-[18px] font-opensans font-bold text-[#313E5A] text-center">
                    Quality Management Systems
                  </p>
                </div>
              </li>
              <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
                <OptimizedImage
                  src="https://www.tomedes.com/static/17100-0ff9c3017e6dba8263d7287b8d9e2c9b.webp"
                  alt="ISO 18587:2017"
                  className="lg:pb-12 w-[10rem] h-auto"
                />
                <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                  <p className="text-[18px] font-opensans font-medium text-[#313E5A] pt-4  text-center">
                    ISO 17100:2015{" "}
                  </p>
                  <p className="text-[18px] font-opensans font-bold text-[#313E5A] text-center">
                    Translation Services Quality
                  </p>
                </div>
              </li>
              <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0 sm:pb-0 pb-4">
                <OptimizedImage
                  src="	https://www.tomedes.com/static/18587-a1c764d0ddf5cec6cfbeef6f79744ba9.webp"
                  alt="ISO 17100:2015"
                  className="lg:pb-12 w-[10rem] h-auto"
                />
                <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                  <p className="text-[18px] font-opensans font-medium text-[#313E5A] pt-4  text-center">
                    ISO 18587:2017{" "}
                  </p>
                  <p className="text-[18px] font-opensans font-bold text-[#313E5A] text-center">
                    Post-editing Machine Translation Output
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {midComp ? (
          <div className="flex flex-wrap justify-between xl:justify-around pt-[10rem] lg:px-[5rem] xl:px-0">
            <div className="">
              <h2 className="text-[40px] sm:text-left text-center font-bold font-secondary">
                {/* Multilingual Support Enabled by Our Specialist Website Translators */}
                <span className="text-orange">Multilingual</span> Support
              </h2>
              <h2 className="text-[40px] sm:text-left text-center font-bold font-secondary">
                Enabled by Our
              </h2>
              <h2 className="text-[40px] sm:text-left text-center font-bold font-secondary">
                Specialist Website
              </h2>
              <h2 className="text-[40px] sm:text-left text-center font-bold font-secondary">
                Translators
              </h2>
            </div>
            <div className="w-full sm:w-[494px] ">
              <p className="text-[18px] font-opensans text-center sm:text-left text-[#00173A] leading-loose px-2">
                Our website translation service is done by website translators
                expert in two or more languages, from our selection of over 120
                languages with 950+ language pairs. They're also industry
                experts that have insights on whichever industry your website
                belongs to, be it cryptocurrency or ecommerce. Tomedes prides
                itself on the skill of our multilingual, multi-industry experts.
                Get started now.
              </p>
            </div>
          </div>
        ) : null}
        {lastSection ? (
          <>
            <h3 className="text-[#00173A] sm:text-left text-center text-[40px] mx-auto font-semibold font-secondary pt-[12rem]">
              Translate into Any Language{" "}
            </h3>
            <div className="flex flex-wrap justify-center mx-auto gap-x-[12rem] pt-[3rem] pb-[6rem] px-[2rem]">
              <div className="pb-4">
                <ul className=" font-sans text-[18px] list-disc flex flex-col gap-y-3">
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                </ul>
              </div>
              <div className="">
                <ul className=" font-sans text-[18px] list-disc flex flex-col gap-y-3">
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                </ul>
              </div>
              <div className="">
                <ul className=" font-sans relative text-[18px] list-disc flex flex-col gap-y-3">
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li>Language One</li>
                  <li
                    className="absolute list-disc bottom-[-38px]"
                    style="width: max-content;"
                  >
                    And much much more…
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {CTA ? (
        <AdContact
          head="Ready to get started?"
          btnName="INQUIRE NOW"
          handleTop={handleTop}
        />
      ) : null}

      {serviceSection ? (
        <Services
          heading="רשת של מעל 20 אלף מתרגמים מומחים"
          paragraphText="לטומדס רשת ענפה של מתרגמים ומומחי שפות מסורים ומנוסים מכל העולם. כל אחד מהמתרגמים והמומחים שלנו נבחר והוכשר במיוחד כדי להתאים לדרישות הספציפיות בעבודות שלכם. המתרגמים שלנו חולשים על שפות רבות ומתמחים במגוון תעשיות. כך הם נותנים לכם מענה לכל צורך.
"
          // secondPara="Our 24/7 consistent customer support and professional project management are ready to assist you."
          background="bg-none"
          addImag={MapPeople}
          forHebrew={forHebrew}
        />
      ) : null}
    </div>
  );
};

export default SeventhAdComp;
