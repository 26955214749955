import React, { useState } from "react";
import Web from "../../assets/web.svg";
import arrowDownSvg from "assets/icons/iconDownWhite.svg";
import ContactusMobile from "components/reusableComponents/contactusMobile/ContactusMobile";

const Services = ({
  firstheading,
  heading,
  paragraphText,
  btn,
  background,
  addressText,
  secondPara,
  servicesdata,
  servicesdata2,
  addImag,
  open,
  setOpen,
  forHebrew,
}) => {
  const [openContactusMobileFour, setOpenContactusMobileFour] = useState(false);

  return (
    <div
      className={`mx-auto pt-20 ${
        openContactusMobileFour ? "pb-[30rem]" : "pb-20"
      }  px-8 sm:px-0 md:px-10 ${background ? background : "bg-[#F6FCFF]"}`}
    >
      <div
        className={`flex  justify-around ${
          forHebrew ? "mx-auto 3xl:w-9/12" : "flex-row 3xl:w-9/12 mx-auto"
        } items-center gap-16 mt-24 sm:my-24`}
      >
        <div className={`${forHebrew ? null : "m-auto"}  sm:block hidden`}>
          <img
            src={addImag ? addImag : Web}
            alt=""
            className="md:w-[437px] md:h-[397px]"
          />
        </div>
        <div>
          <h2
            className={` ${
              forHebrew ? "text-right" : ""
            } font-secondary text-[35px] sm:text-40 text-blue font-bold max-w-xl mb-8 text-rtl`}
          >
            <span className="text-orange"> {firstheading} </span>
            {heading ? heading : "A Wide Portfolio Of Translation Solutions"}
          </h2>
          <p
            className={` ${
              forHebrew ? "text-right" : ""
            } font-opensans text-[16px] sm:text-lg mb-10 max-w-xl text-[#00173A] text-rtl`}
          >
            {paragraphText}
          </p>
          <p className={` ${forHebrew ? "text-right" : ""} font-opensans text-[16px] sm:text-lg mb-10 max-w-xl text-[#00173A]`}>
            {secondPara}
          </p>
          <div className="hidden sm:block relative">
            <ul className="grid sm:grid-cols-2 max-w-2xl">
              {servicesdata
                ? servicesdata.map((item, i) => {
                    return (
                      <li className={` ${forHebrew ? "flex-row-reverse" : ""} flex gap-4 mb-4`} key={i}>
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt=""
                            className="w-[24px] h-[20px]"
                          />
                        )}
                        {item.text && (
                          <span className="font-opensans text-lg text-[#00173A]">
                            {item.text}{" "}
                          </span>
                        )}
                      </li>
                    );
                  })
                : null}

              {btn ? (
                <button
                  onClick={() => setOpen(true)}
                  type="submit"
                  className={`mt-14 sm:inline-block hidden bg-orange font-opensans text-14 font-semibold text-white w-[302px] sm:w-[213px] h-[59px] cursor-pointer rounded-full ${forHebrew ? "absolute right-0 top-[100px]" : ""}`}
                >
                  צרו קשר
                </button>
              ) : null}
            </ul>
          </div>
          <div className="sm:hidden">
            <div className="grid sm:grid-cols-2 max-w-2xl">
              {servicesdata2
                ? servicesdata2.map((item, i) => {
                    return (
                      <div className="flex flex-row-reverse gap-4 mb-4" key={i}>
                        {item.icon && <img src={item.icon} alt="" className="w-[24px] h-[20px]" />}
                        {item.text && (
                          <span className="font-opensans text-lg text-[#00173A]">
                            {item.text}{" "}
                          </span>
                        )}
                      </div>
                    );
                  })
                : null}

              {btn ? (
                <>
                  <button
                    onClick={() => setOpenContactusMobileFour(true)}
                    type="submit"
                    className="mt-14 sm:hidden flex justify-center items-center gap-x-4 bg-orange font-opensans text-14 font-semibold text-white w-[302px] sm:w-[213px] h-[59px] cursor-pointer rounded-full relative"
                  >
                    צרו קשר
                    <img
                      src={arrowDownSvg}
                      alt="arrow down"
                      className={`${
                        openContactusMobileFour
                          ? "visible"
                          : "invisible absolute"
                      } `}
                    />
                  </button>
                  <div
                    className={`absolute left-0 ${
                      openContactusMobileFour && "mt-[16rem]"
                    }`}
                  >
                    <ContactusMobile
                      open={openContactusMobileFour}
                      setOpen={setOpenContactusMobileFour}
                      noPlaceholder
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
