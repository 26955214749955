import React from "react";

export default function ThankYouPopup({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary">
            <div className="relative  my-6 mx-auto w-80">
              <div className="border-0 p-[10px] rounded-lg shadow-lg relative flex text-black flex-col w-full bg-white outline-none focus:outline-none ">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/check_dark.svg"
                  alt="check_dark"
                  className="w-18 block mx-auto py-4"
                />
                <h3 className="text-3xl font-semibold font-secondary text-center">
                  תודה רבה!{" "}
                </h3>

                <div className="relative p-3 flex-auto">
                  <p
                    className=" font-sans text-blueGray-500 text-lg leading-relaxed text-center swal2-html-container"
                    id="swal2-content"
                  >
                    נחזור אליך בהקדם.
                  </p>
                </div>
                <button
                  className="block mx-auto text-white bg-orange font-bold uppercase w-24 px-6 py-2 text-sm outline-none focus:outline-none mb-3 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
    </>
  );
}
