import React, { useState } from 'react'
import { useLocationGetInTouch } from 'hooks/Forms/useLocationGetInTouch'
import { useForm } from 'react-hook-form'
import ThankYouPopup from 'components/popup/thankYouPopup'
import Loader from 'components/loader'
import { useLocation } from '@reach/router'
import WhiteClose from 'assets/close_light.svg'
import formCommonPostData from 'utils/form-common-post-data'

export default function ContactusMobile({
  open,
  setOpen,
  blueTheme,
  noPlaceholder,
  forHebrew,
}) {
  const [thankOpen, setThankOpen] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { status, mutateAsync } = useLocationGetInTouch()
  const location = useLocation()

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    }

    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset()
      setThankOpen(true)
      setOpen(false)
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  let blackText = { color: '#000C27' }
  let whiteText = { color: '#ffff' }

  return (
    <>
      {open && (
        <React.Fragment>
          <div
            className={`justify-center items-center ${
              forHebrew ? 'flex-none' : 'flex'
            }   outline-none focus:outline-none font-primary`}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`relative mt-[22px] mx-[24px] flex items-center justify-between w-full ${
                blueTheme ? 'bg-blue' : 'bg-white'
              }   flex-col py-5 mb-11`}
            >
              <div className="px-8 relative right-0">
                <button
                  className="closetbn block ml-auto font-bold w-[24px] outline-none focus:outline-none  ease-linear transition-all duration-150 "
                  type="button"
                  onClick={handleClose}
                >
                  {blueTheme ? (
                    <img src={WhiteClose} alt="" />
                  ) : (
                    <img src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/close_dark.svg" />
                  )}
                </button>
                <h5 className="text-orange font-secondary  font-bold text-3xl text-center py-5">
                  צרו קשר
                </h5>
                <p
                  className="font-opensans text-sm text-center  pb-6 "
                  style={blueTheme ? whiteText : blackText}
                >
                  הזינו את פרטי הקשר שלכם ונחזור אליכם בתוך כמה דקות
                </p>
                <div className="flex justify-between items-center  flex-wrap lg:flex-nowrap lg:flex-row flex-col lg:gap-3">
                  <label className="flex flex-col w-full">
                    <span
                      className="modal-label font-opensans text-[11px]"
                      style={blueTheme ? whiteText : blackText}
                    >
                      שם<i className="text-red">*</i>
                    </span>
                    <input
                      type="text"
                      name="name"
                      required
                      className="modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-2 py-1 w-full border border-pop-up-100 h-[32px] outline-none"
                      placeholder="שם"
                      {...register('name', { required: true })}
                    />
                    {/* {errors.name && (
                      <span className="text-red text-xs">
                        This field is required
                      </span>
                    )} */}
                  </label>
                  <label className="flex flex-col w-full pb-[10px]">
                    <span
                      className="modal-label font-opensans text-[11px] "
                      style={blueTheme ? whiteText : blackText}
                    >
                      כתובת דואל<i className="text-red">*</i>
                    </span>
                    <input
                      type="email"
                      name="email"
                      required
                      className="modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-2 py-1 w-full border border-pop-up-100 h-[32px] outline-none"
                      placeholder="כתובת דואל"
                      {...register('email', {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {/* {errors.email && (
                      <span className="text-red text-xs">
                        This field is required
                      </span>
                    )} */}
                  </label>
                </div>
                <label>
                  <span
                    className="modal-label font-opensans text-[11px] mt-1 flex justify-end"
                    style={blueTheme ? whiteText : blackText}
                  >
                    ספרו לנו מה הצורך שלכם
                  </span>
                  {noPlaceholder ? (
                    <textarea
                      id=""
                      name="message"
                      rows="2"
                      style="resize:none;"
                      className="text-right text-rtl modal-input px-2 py-2 font-opensans text-[11px] text-pop-up-200 border border-pop-up-100 w-full"
                      {...register('message')}
                    />
                  ) : (
                    <textarea
                      id=""
                      name="message"
                      rows="2"
                      style="resize:none;"
                      className="modal-input px-2 py-2 font-opensans text-[11px] text-pop-up-200 border border-pop-up-100 w-full"
                      placeholder="חבר בצוות טומדס ייצור אתכם קשר בתוך כמה דקות"
                      {...register('message')}
                    />
                  )}

                  {errors.message && (
                    <span className="text-red text-xs">
                      This field is required
                    </span>
                  )}
                </label>
                <button
                  className="btn orn-btn w-full lg:w-32 h-10 text-white bg-orange font-opensans font-bold block mx-auto mt-2"
                  id="emailsubmit"
                >
                  {status === 'loading' ? <Loader /> : 'שלח'}
                </button>
              </div>
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            </form>
          </div>
        </React.Fragment>
      )}
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </>
  )
}
